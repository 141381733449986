// getRemoteConfigData.js
'use strict';
import { Collection, Map } from 'immutable';

import replaceTemplate from '../resource/replaceTemplate.js';
import createCachedSelector from '../resource/createCachedSelector.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { PUBLIC } from '../resource/resourceUrlTypeConstants.js';
import {
  TOKEN_EXPIRED_THRESHOLD,
  FLIX_SPLASH_DASH,
  FLIX_SPLASH_HLS,
  FEATURE_LIVESTREAM_DISPLAY_USERNAMES,
  LOGIN_METHODS_PRIMARY,
  LOGIN_METHODS_SECONDARY,
  SIGNUP_METHODS_PRIMARY,
  SIGNUP_METHODS_SECONDARY,
  MESSAGE_BADGE_NONE,
  FLIX_TITLE_CHARS_MAX,
  FLIX_CAPTION_CHARS_MAX,
  FEATURE_ACCOUNT_REFERRAL_CODE,
  FEATURE_ACCOUNT_REFERRAL_CODE_CREATOR,
  LEADERBOARD_CATEGORIES,
  HASHTAG_CATEGORIES,
  DRM_LICENSE_DURATION,
  LEADERBOARD_IDS,
  FAQ_SUPPORTED_LANGUAGES,
  JOURNAL_V2_FIELD_DATETIME,
  JOURNAL_V2_FIELD_PRICE,
  JOURNAL_V2_FIELD_GIFT,
  JOURNAL_V2_FIELD_PRODUCT,
  JOURNAL_V2_FIELD_OEMBED,
  JOURNAL_V2_FIELD_DIAMOND,
  SUPPORTED_LANGUAGES,
  NEED_LANDING_COUNTRIES,
  KYC_SUPPORTED_COUNTRIES,
  KYC_FRONT_ONLY,
  CREDIT_CARD_TYPES_TAPPAY,
  CREDIT_CARD_TYPES_SECURIONPAY,
  FLIX_DISPLAY_UNLOCK_COUNT,
  HOME_HEADER_DISPLAY_FREE_BADGE,
  HOME_HEADER_DISPLAY_NEW_BADGE,
  DIAMOND_SHOP_METHOD_TYPES_BLACKLIST,
  SHOP_CURRENCIES,
  ZERO_DECIMAL_CURRENCIES,
  HOME_BANNERS,
  HOME_BANNER_PADDINGS,
  VIDEO_BANNERS,
  VIDEO_BANNER_PADDINGS,
  REGISTER_OPTIONAL_BIND_EMAIL_MODAL,
  REGISTER_OPTIONAL_BIND_PHONE_NUMBER_MODAL,
  REGISTER_OPTIONAL_AGE_VERIFY_NOTIFICATION,
  REGISTER_OPTIONAL_AGE_VERIFY,
  GIFT_CATEGORIES_CHAT,
  GIFT_CATEGORIES_LIVESTREAM,
  LIVESTREAM_ANIMATION_GIFTS,
  PING_CHECK_URLS,
  POST_COMMENT_MAX_LENGTH,
  POST_DETAIL_THUMBNAIL_SIZE,
  ASSET_FILE_SIZE_MAX,
  ARTIFACT_TRAILER_FILE_SIZE_MAX,
  ARTIFACT_THUMBNAIL_FILE_SIZE_MAX,
  UPLOADER_VIDEO_LENGTH_SEC_MIN_MAX,
  MESSAGE_VIDEO_LENGTH_SEC_MIN_MAX,
  POST_DIAMOND_MIN,
  POST_DIAMOND_MAX,
  POST_DIAMOND_INTERVAL,
  POST_DIAMOND_DEFAULT,
  POST_IMAGE_DIAMOND_MIN,
  POST_IMAGE_DIAMOND_MAX,
  POST_IMAGE_DIAMOND_INTERVAL,
  POST_IMAGE_DIAMOND_DEFAULT,
  POST_ASSET_MAX_QUANTITIES,
  POST_COVER_IMAGE_SIZE,
  BROADCAST_MESSAGE_VIDEO_DIAMOND_INTERVAL,
  BROADCAST_MESSAGE_VIDEO_DIAMOND_MAX,
  BROADCAST_MESSAGE_VIDEO_DIAMOND_MIN,
  CHAT_VIDEO_LENGTH_MAX,
  STORY_TTL_SECONDS,
  CP_REPLY_DIAMOND_MIN_MAX_INTERVAL,
  SUBSCRIPTION_PLAN_AMOUNT_MIN,
  SUBSCRIPTION_PLAN_AMOUNT_MAX,
  BIOGRAPHY_MAX_LENGTH,
  DISPLAY_NAME_MAX_LENGTH,
  FEATURE_DISPLAY_NAME,
  FEATURE_WEB_AUTHN,
  PAYOUT_PAYMENT_SUPPORTED_METHODS,
  BACKPACK_EXPIRING_THRESHOLD_SECS,
  FEATURE_CREATOR_ENTRY,
  ENABLED_LOW_LATENCY,
  LOW_LATENCY_LIVE_DELAY_PUBLIC,
  LOW_LATENCY_LIVE_DELAY_PRIVATE,
  LOW_LATENCY_PLAYER_WAITING_HINT_COOL_DOWN_SECONDS,
  LOW_LATENCY_PLAYER_WAITING_WINDOW_SECONDS,
  LOW_LATENCY_PLAYER_WAITING_MAX_SECONDS_PER_WINDOW,
  LOW_LATENCY_PLAYER_WAITING_FRAGMENT_INTERVAL_SECONDS,
  SHORT_VIDEO_DURATION_SECONDS,
  BLUROUT_PARAMETERS,
  LIVESTREAM_FEED_SORTINGS,
  OUTBOX_FEED_POST_IMAGE_SORTINGS,
  OUTBOX_FEED_SHORT_SORTINGS,
  OUTBOX_FEED_STORY_SORTINGS,
  OUTBOX_FEED_POST_VIDEO_SORTINGS,
  OUTBOX_FEED_ALL_DRAFT_SORTINGS,
  OUTBOX_FEED_ALL_DRAFT_FILTERS,
  HASHTAG_FEED_STORY_SORTINGS,
  HASHTAG_FEED_POST_VIDEO_SORTINGS,
  JWT_SCOPE_CURATOR,
  JWT_SCOPE_CREATOR,
  JWT_SCOPE_SUBSCRIBABLE,
  JWT_SCOPE_STATS_PERSONAL,
  JWT_SCOPE_UPLOADER_POST,
  JWT_SCOPE_UPLOADER_BROADCAST,
  JWT_SCOPE_UPLOADER_CHAT,
  JWT_SCOPE_UPLOADER_REPLY,
  JWT_SCOPE_MESSAGE_CLIP,
  JWT_SCOPE_LIVESTREAM,
  JWT_SCOPE_LIVESTREAM_CHAT,
  JWT_SCOPE_MESSAGE_HIDE,
  JWT_SCOPE_MESSAGE_EDIT,
  DRM_CAPABILITY_CHECK_MESSAGE_IDS,
  DASHJS_INSUFFICIENT_BUFFER_RULE,
  DASHJS_SWITCH_HISTORY_RULE,
  DASHJS_DROPPED_FRAMES_RULE,
  DASHJS_ABANDON_REQUESTS_RULE,
  DASHJS_BUFFER_STALLED_THRESHOLD,
  DASHJS_CATCH_UP_ENABLED,
  DASHJS_CATCH_UP_MAX_DRIFT,
  DASHJS_CATCH_UP_PLAYBACK_RATE_MAX,
  DASHJS_CATCH_UP_PLAYBACK_RATE_MIN,
  DASHJS_LOW_LATENCY_INSUFFICIENT_BUFFER_RULE,
  DASHJS_LOW_LATENCY_SWITCH_HISTORY_RULE,
  DASHJS_LOW_LATENCY_DROPPED_FRAMES_RULE,
  DASHJS_LOW_LATENCY_ABANDON_REQUESTS_RULE,
  DASHJS_LOW_LATENCY_BUFFER_STALLED_THRESHOLD,
  DASHJS_LOW_LATENCY_CATCH_UP_ENABLED,
  DASHJS_LOW_LATENCY_CATCH_UP_MAX_DRIFT,
  DASHJS_LOW_LATENCY_CATCH_UP_PLAYBACK_RATE_MAX,
  DASHJS_LOW_LATENCY_CATCH_UP_PLAYBACK_RATE_MIN,
  DASHJS_CMCD_ENABLED,
  DASHJS_CMSD_ENABLED,
  DASHJS_AV_RESYNC_INTERVAL_SEC,
  LIVESTREAM_AVAILABILITY_CHECK_TIMEOUT_MSEC,
  LIVESTREAM_PLAYER_WAITING_THRESHOLD_MSEC,
  LIVESTREAM_FLV_MIN_BUFFER,
  LIVESTREAM_FLV_MAX_BUFFER,
  LIVESTREAM_PAY_DURATION_SECONDS,
  BYTEPLUS_RTC_ROOM_PROFILE_TYPE,
  BYTEPLUS_RTC_REMOTE_VIDEO_CONFIG_WIDTH,
  BYTEPLUS_RTC_REMOTE_VIDEO_CONFIG_HEIGHT,
  FLIX_PRICE_VALUE,
  AUTO_MESSAGE_PRICE_VALUE,
  LIVESTREAM_PRIVATE_PRICE_VALUE,
  LIVESTREAM_SHOW_TICKET_PRICE_VALUE,
  LIVESTREAM_SHOW_EARLY_BIRD_TICKET_PRICE_VALUE,
  SHORTS_CTA_DELAY_SECONDS,
  LIVESTREAM_SHORTS_CTA_DELAY_SECONDS,
  REFERRAL_REFERRER_REWARD_VALUE,
  REFERRAL_REFERRAL_REWARD_VALUE,
  FEATURE_USERNAME_SIGN_UP_EMAIL_INPUT,
  SHORTS_NEED_AUTH_THRESHOLD,
  IFRAME_MAIN_DOMAIN_WHITELIST,
  FEATURE_STORY_VOLUME_BUTTON,
  POST_VIDEO_LENGTH_MIN,
  POST_VIDEO_LENGTH_MAX,
  PUBLIC_LOGO_URL_PATH,
  GAME_NEXT_SESSION_THRESHOLD_SECONDS,
  INSERT_CLIP_AFTER_X_SHORTS,
  INSERT_CLIP_EVERY_X_SHORTS,
  INSERT_AD_AFTER_X_SHORTS,
  INSERT_AD_EVERY_X_SHORTS,
  CREATOR_OUTBOX_FEED_STORY_PIN_MAX,
  CREATOR_OUTBOX_FEED_POST_VIDEO_PIN_MAX,
  CREATOR_OUTBOX_FEED_POST_IMAGE_PIN_MAX,
  CREATOR_OUTBOX_FEED_SHORT_PIN_MAX,
  SHORT_VIDEO_LENGTH_MIN,
  FEATURE_CLIP_POST,
  APP_BUNDLE_IDS,
  PAYMENT_SUPPORTED_METHODS_USD,
  PAYMENT_SUPPORTED_METHODS_TWD,
  PAYMENT_SUPPORTED_METHODS_CNY,
  PAYMENT_SUPPORTED_METHODS_AUD,
  PAYMENT_SUPPORTED_METHODS_JPY,
  PAYMENT_SUPPORTED_METHODS_CAD,
  PAYMENT_SUPPORTED_METHODS_EUR,
  PAYMENT_SUPPORTED_METHODS_GBP,
  PAYMENT_SUPPORTED_METHODS_HKD,
  PAYMENT_SUPPORTED_METHODS_KRW,
  PAYMENT_SUPPORTED_METHODS_MYR,
  PAYMENT_SUPPORTED_METHODS_NZD,
  PAYMENT_SUPPORTED_METHODS_PHP,
  PAYMENT_SUPPORTED_METHODS_SGD,
  PAYMENT_SUPPORTED_METHODS_THB,
  PAYMENT_SUPPORTED_METHODS_VND,
  HOME_FEATURE_CATEGORIES,
  HOME_VIDEO_CATEGORIES,
  BROWSE_FOLLOWING_CATEGORIES,
  BROWSE_EXPLORE_CATEGORIES,
  BROWSE_TABS,
  FEED_CATEGORIES,
  FOLLOWING_PREFETCHED_MESSAGE_AMOUNT,
  SHORTS_PREFETCHED_MESSAGE_AMOUNT,
  USER_PROFILE_HOME_CATEGORIES,
  USER_PROFILE_TABS_GROUP,
  USER_PROFILE_TABS,
  USER_PROFILE_TABS_DRAFT,
  LIVESTREAM_OPERATION_HINT,
  LIVESTREAM_EXIT_ALERT,
  SWAG_SOCIAL_MEDIA_LINKS,
  SWAG_ABOUT_LINKS,
  SWAG_SUPPORT_LINKS,
  SWAG_WORK_LINKS,
  SWAG_DISCOVER_LINKS,
  TRAILER_VIDEO_UPLOAD_ENABLED,
  TRAILER_POST_VIDEO_LENGTH_SEC_MIN,
  TRAILER_VIDEO_LENGTH_SEC_MIN_MAX,
  PUSHER_STALLED_CHANNEL_CHECK_INTERVAL,
  PUSHER_STALLED_CHANNEL_TRACK_LIMIT,
  FEATURE_HIDE_POST,
  MAX_VALID_AGE_VERIFIED_MSEC,
  SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE,
  MIXPANEL_INIT_EVENTS,
  MIXPANEL_RECORD_SESSIONS_PERCENT,
  DIAMOND_SHOP_TYPES,
  LIVESTREAM_AVATAR_QUEUE_DELAY_MSEC,
  LIVESTREAM_CHAT_LIST_LIMITATION,
  WEB_AUTHN_DESKTOP_SUPPORT_ENABLED,
  WEB_AUTHN_REGISTRATION_TIMEOUT_MSEC,
  WEB_AUTHN_AUTHENTICATION_TIMEOUT_MSEC,
  SCHEDULE_POST_DEFAULT_DURATION_SEC,
  OUTBOX_FEED_DELAY_TIME_IN_SECONDS,
  FEATURE_GAMES,
  GAME_DRAGON_TIGER_FIGHT_LANGUAGES,
  GAME_AQUISITION_AUTH_TOKEN_THRESHOLD_SECONDS,
  FEATURE_SECURIONPAY_SHOP_AGREEMENT,
  FEATURE_TAPPAY_SHOP_AGREEMENT,
  FEATURE_ECPAY_SHOP_AGREEMENT,
  SKIP_SSR_FETCH_QUERIES,
  FETCH_MESSAGE_DETAIL_CACHE_BUSTER_QUERIES,
  ACQUISITION_TYPES_CREATOR_REFERRAL,
  ACQUISITION_TYPES_PREPAID_CARD,
  ACQUISITION_TYPES_USER_REFERRAL,
  ACQUISITION_TYPES_REDEEM,
  LIVESTREAM_KARAOKE_NAME_DISPLAY_ORDER,
  LIVESTREAM_LOVENSE_NAME_DISPLAY_ORDER,
  FEATURE_SIGNUP_BIRTHDATE_INPUT,
  LIVESTREAM_LOW_DEFINITION_VIDEO_IDS,
  SHORTS_ADS_V2,
  FEATURE_EDIT_POST,
  SEARCH_MESSAGE_SORTINGS,
  SEARCH_USER_SORTINGS,
  PUSHER_CHANNEL_DATA_POLLING_MSEC,
  FEATURE_MESSAGE_CATEGORY,
  TRENDING_CATEGORY_FETCH_LIMIT,
  MESSAGE_CATEGORY_SELECTION_COUNT_MAX,
  MESSAGE_CATEGORY_SELECTION_COUNT_MIN,
  MESSAGE_CATEGORY_GROUPINGS,
  MESSAGE_CATEGORY_TABS,
  CATEGORY_FEED_STORY_SORTINGS,
  CATEGORY_FEED_POST_VIDEO_SORTINGS,
  CATEGORY_FEED_SHORT_SORTINGS,
  CATEGORY_FEED_POST_IMAGE_SORTINGS,
  CATEGORY_FEED_ALL,
  FEATURE_EXPIRE_POST,
  FEATURE_EXPIRE_EDIT_POST,
  EARLYBIRD_DISPLAY_TICKET_DIAMOND,
  SHOW_DISPLAY_TICKET_DIAMOND,
  FEATURE_MINI_PROFILE,
  FEATURE_SETTINGS_REDEEM,
  FEATURE_SETTINGS_ABOUT_SWAG,
  FEATURE_EMAIL_BINDING_TOASTR,
  IMPRESSION_TRACKING_VISIBILITY_RATIO,
  IMPRESSION_TRACKING_DURATION_SECONDS,
  USER_LEVEL_1_BENEFIT,
  USER_LEVEL_2_BENEFIT,
  USER_LEVEL_3_BENEFIT,
  USER_LEVEL_4_BENEFIT,
  USER_LEVEL_5_BENEFIT,
  USER_LEVEL_6_BENEFIT,
  USER_LEVEL_7_BENEFIT,
  USER_LEVEL_8_BENEFIT,
  USER_LEVEL_ALL_BENEFITS,
  VIP_LEVEL_LIVESTREAM_NOTIFY_THRESHOLD,
  VIP_LEVEL_DIAMONDS,
  VIP_LEVEL_INFORM_EXPIRE_SOON_DAYS,
  VIP_LEVEL_1_LIVESTREAM_STYLE,
  VIP_LEVEL_2_LIVESTREAM_STYLE,
  VIP_LEVEL_3_LIVESTREAM_STYLE,
  VIP_LEVEL_4_LIVESTREAM_STYLE,
  VIP_LEVEL_5_LIVESTREAM_STYLE,
  VIP_LEVEL_6_LIVESTREAM_STYLE,
  VIP_LEVEL_7_LIVESTREAM_STYLE,
  VIP_LEVEL_8_LIVESTREAM_STYLE,
  RESEND_PHONE_VERIFIED_CODE_SEC,
  RESEND_EMAIL_VERIFIED_CODE_SEC,
  LIVESTREAM_VIEWERS_MAX,
  LIVESTREAM_VIEWERS_MIN,
  FEATURE_LIVESTREAM_OVERLAY_CTA,
  INTERCOM_UPDATE_DEBOUNCE_MSEC,
  LIVESTREAM_CHAT_SCROLL_DETECT_ITEM_COUNT,
  SCROLL_MEMORY_DISABLED_LIST,
} from '../RemoteConfigKeys.js';

const LOGIN_METHODS = [
  LOGIN_METHODS_PRIMARY,
  LOGIN_METHODS_SECONDARY,
  SIGNUP_METHODS_PRIMARY,
  SIGNUP_METHODS_SECONDARY,
];

const PAYMENT_SUPPORTED_METHODS = [
  PAYMENT_SUPPORTED_METHODS_USD,
  PAYMENT_SUPPORTED_METHODS_TWD,
  PAYMENT_SUPPORTED_METHODS_CNY,
  PAYMENT_SUPPORTED_METHODS_AUD,
  PAYMENT_SUPPORTED_METHODS_JPY,
  PAYMENT_SUPPORTED_METHODS_CAD,
  PAYMENT_SUPPORTED_METHODS_EUR,
  PAYMENT_SUPPORTED_METHODS_GBP,
  PAYMENT_SUPPORTED_METHODS_HKD,
  PAYMENT_SUPPORTED_METHODS_KRW,
  PAYMENT_SUPPORTED_METHODS_MYR,
  PAYMENT_SUPPORTED_METHODS_NZD,
  PAYMENT_SUPPORTED_METHODS_PHP,
  PAYMENT_SUPPORTED_METHODS_SGD,
  PAYMENT_SUPPORTED_METHODS_THB,
  PAYMENT_SUPPORTED_METHODS_VND,
];

const USER_LEVEL_BENEFIT = [
  USER_LEVEL_1_BENEFIT,
  USER_LEVEL_2_BENEFIT,
  USER_LEVEL_3_BENEFIT,
  USER_LEVEL_4_BENEFIT,
  USER_LEVEL_5_BENEFIT,
  USER_LEVEL_6_BENEFIT,
  USER_LEVEL_7_BENEFIT,
  USER_LEVEL_8_BENEFIT,
];

const VIP_LEVEL_LIVESTREAM_CHAT = [
  VIP_LEVEL_1_LIVESTREAM_STYLE,
  VIP_LEVEL_2_LIVESTREAM_STYLE,
  VIP_LEVEL_3_LIVESTREAM_STYLE,
  VIP_LEVEL_4_LIVESTREAM_STYLE,
  VIP_LEVEL_5_LIVESTREAM_STYLE,
  VIP_LEVEL_6_LIVESTREAM_STYLE,
  VIP_LEVEL_7_LIVESTREAM_STYLE,
  VIP_LEVEL_8_LIVESTREAM_STYLE,
];

const defaultMap = new Map();
const defaultList = [];
const needSplitKeys = [
  NEED_LANDING_COUNTRIES,
  MESSAGE_BADGE_NONE,
  LEADERBOARD_IDS,
  LEADERBOARD_CATEGORIES,
  HASHTAG_CATEGORIES,
  FAQ_SUPPORTED_LANGUAGES,
  KYC_SUPPORTED_COUNTRIES,
  KYC_FRONT_ONLY,
  JOURNAL_V2_FIELD_DATETIME,
  JOURNAL_V2_FIELD_PRICE,
  JOURNAL_V2_FIELD_GIFT,
  JOURNAL_V2_FIELD_PRODUCT,
  JOURNAL_V2_FIELD_OEMBED,
  JOURNAL_V2_FIELD_DIAMOND,
  CREDIT_CARD_TYPES_TAPPAY,
  CREDIT_CARD_TYPES_SECURIONPAY,
  DIAMOND_SHOP_METHOD_TYPES_BLACKLIST,
  SHOP_CURRENCIES,
  ZERO_DECIMAL_CURRENCIES,
  HOME_BANNERS,
  HOME_BANNER_PADDINGS,
  VIDEO_BANNERS,
  VIDEO_BANNER_PADDINGS,
  ...LOGIN_METHODS,
  GIFT_CATEGORIES_CHAT,
  GIFT_CATEGORIES_LIVESTREAM,
  LIVESTREAM_ANIMATION_GIFTS,
  PING_CHECK_URLS,
  UPLOADER_VIDEO_LENGTH_SEC_MIN_MAX,
  MESSAGE_VIDEO_LENGTH_SEC_MIN_MAX,
  PAYOUT_PAYMENT_SUPPORTED_METHODS,
  BLUROUT_PARAMETERS,
  LIVESTREAM_FEED_SORTINGS,
  OUTBOX_FEED_POST_IMAGE_SORTINGS,
  OUTBOX_FEED_SHORT_SORTINGS,
  OUTBOX_FEED_STORY_SORTINGS,
  OUTBOX_FEED_POST_VIDEO_SORTINGS,
  OUTBOX_FEED_ALL_DRAFT_SORTINGS,
  OUTBOX_FEED_ALL_DRAFT_FILTERS,
  HASHTAG_FEED_STORY_SORTINGS,
  HASHTAG_FEED_POST_VIDEO_SORTINGS,
  JWT_SCOPE_CURATOR,
  JWT_SCOPE_CREATOR,
  JWT_SCOPE_SUBSCRIBABLE,
  JWT_SCOPE_STATS_PERSONAL,
  JWT_SCOPE_UPLOADER_POST,
  JWT_SCOPE_UPLOADER_BROADCAST,
  JWT_SCOPE_UPLOADER_CHAT,
  JWT_SCOPE_UPLOADER_REPLY,
  JWT_SCOPE_MESSAGE_CLIP,
  JWT_SCOPE_LIVESTREAM,
  JWT_SCOPE_LIVESTREAM_CHAT,
  JWT_SCOPE_MESSAGE_HIDE,
  JWT_SCOPE_MESSAGE_EDIT,
  DRM_CAPABILITY_CHECK_MESSAGE_IDS,
  IFRAME_MAIN_DOMAIN_WHITELIST,
  APP_BUNDLE_IDS,
  ...PAYMENT_SUPPORTED_METHODS,
  HOME_FEATURE_CATEGORIES,
  HOME_VIDEO_CATEGORIES,
  BROWSE_FOLLOWING_CATEGORIES,
  BROWSE_EXPLORE_CATEGORIES,
  BROWSE_TABS,
  FEED_CATEGORIES,
  USER_PROFILE_HOME_CATEGORIES,
  USER_PROFILE_TABS_GROUP,
  USER_PROFILE_TABS,
  USER_PROFILE_TABS_DRAFT,
  TRAILER_VIDEO_LENGTH_SEC_MIN_MAX,
  MIXPANEL_INIT_EVENTS,
  DIAMOND_SHOP_TYPES,
  FEATURE_GAMES,
  GAME_DRAGON_TIGER_FIGHT_LANGUAGES,
  SKIP_SSR_FETCH_QUERIES,
  FETCH_MESSAGE_DETAIL_CACHE_BUSTER_QUERIES,
  ACQUISITION_TYPES_CREATOR_REFERRAL,
  ACQUISITION_TYPES_PREPAID_CARD,
  ACQUISITION_TYPES_USER_REFERRAL,
  ACQUISITION_TYPES_REDEEM,
  LIVESTREAM_KARAOKE_NAME_DISPLAY_ORDER,
  LIVESTREAM_LOVENSE_NAME_DISPLAY_ORDER,
  LIVESTREAM_LOW_DEFINITION_VIDEO_IDS,
  SHORTS_ADS_V2,
  SEARCH_MESSAGE_SORTINGS,
  SEARCH_USER_SORTINGS,
  MESSAGE_CATEGORY_GROUPINGS,
  MESSAGE_CATEGORY_TABS,
  CATEGORY_FEED_STORY_SORTINGS,
  CATEGORY_FEED_POST_VIDEO_SORTINGS,
  CATEGORY_FEED_SHORT_SORTINGS,
  CATEGORY_FEED_POST_IMAGE_SORTINGS,
  CATEGORY_FEED_ALL,
  VIP_LEVEL_DIAMONDS,
  USER_LEVEL_ALL_BENEFITS,
  ...USER_LEVEL_BENEFIT,
  ...VIP_LEVEL_LIVESTREAM_CHAT,
  SCROLL_MEMORY_DISABLED_LIST,
];
const needSplitKeysWithPrefix = ['MESSAGE_CATEGORY_GROUPING_'];
const needNumberKeys = [
  TOKEN_EXPIRED_THRESHOLD,
  FLIX_TITLE_CHARS_MAX,
  FLIX_CAPTION_CHARS_MAX,
  FEATURE_ACCOUNT_REFERRAL_CODE,
  FEATURE_ACCOUNT_REFERRAL_CODE_CREATOR,
  DRM_LICENSE_DURATION,
  REGISTER_OPTIONAL_BIND_EMAIL_MODAL,
  REGISTER_OPTIONAL_BIND_PHONE_NUMBER_MODAL,
  REGISTER_OPTIONAL_AGE_VERIFY_NOTIFICATION,
  REGISTER_OPTIONAL_AGE_VERIFY,
  POST_COMMENT_MAX_LENGTH,
  POST_DETAIL_THUMBNAIL_SIZE,
  ASSET_FILE_SIZE_MAX,
  ARTIFACT_TRAILER_FILE_SIZE_MAX,
  ARTIFACT_THUMBNAIL_FILE_SIZE_MAX,
  POST_ASSET_MAX_QUANTITIES,
  POST_COVER_IMAGE_SIZE,
  SUBSCRIPTION_PLAN_AMOUNT_MIN,
  SUBSCRIPTION_PLAN_AMOUNT_MAX,
  BIOGRAPHY_MAX_LENGTH,
  DISPLAY_NAME_MAX_LENGTH,
  FEATURE_DISPLAY_NAME,
  FEATURE_WEB_AUTHN,
  BACKPACK_EXPIRING_THRESHOLD_SECS,
  FEATURE_CREATOR_ENTRY,
  HOME_HEADER_DISPLAY_NEW_BADGE,
  ENABLED_LOW_LATENCY,
  LOW_LATENCY_LIVE_DELAY_PUBLIC,
  LOW_LATENCY_LIVE_DELAY_PRIVATE,
  LOW_LATENCY_PLAYER_WAITING_HINT_COOL_DOWN_SECONDS,
  LOW_LATENCY_PLAYER_WAITING_WINDOW_SECONDS,
  LOW_LATENCY_PLAYER_WAITING_MAX_SECONDS_PER_WINDOW,
  LOW_LATENCY_PLAYER_WAITING_FRAGMENT_INTERVAL_SECONDS,
  SHORT_VIDEO_DURATION_SECONDS,
  BROADCAST_MESSAGE_VIDEO_DIAMOND_INTERVAL,
  BROADCAST_MESSAGE_VIDEO_DIAMOND_MAX,
  BROADCAST_MESSAGE_VIDEO_DIAMOND_MIN,
  CHAT_VIDEO_LENGTH_MAX,
  STORY_TTL_SECONDS,
  DASHJS_INSUFFICIENT_BUFFER_RULE,
  DASHJS_SWITCH_HISTORY_RULE,
  DASHJS_DROPPED_FRAMES_RULE,
  DASHJS_ABANDON_REQUESTS_RULE,
  DASHJS_BUFFER_STALLED_THRESHOLD,
  DASHJS_LOW_LATENCY_INSUFFICIENT_BUFFER_RULE,
  DASHJS_LOW_LATENCY_SWITCH_HISTORY_RULE,
  DASHJS_LOW_LATENCY_DROPPED_FRAMES_RULE,
  DASHJS_LOW_LATENCY_ABANDON_REQUESTS_RULE,
  DASHJS_CATCH_UP_ENABLED,
  DASHJS_CATCH_UP_MAX_DRIFT,
  DASHJS_CATCH_UP_PLAYBACK_RATE_MAX,
  DASHJS_CATCH_UP_PLAYBACK_RATE_MIN,
  DASHJS_LOW_LATENCY_BUFFER_STALLED_THRESHOLD,
  DASHJS_LOW_LATENCY_CATCH_UP_ENABLED,
  DASHJS_LOW_LATENCY_CATCH_UP_MAX_DRIFT,
  DASHJS_LOW_LATENCY_CATCH_UP_PLAYBACK_RATE_MAX,
  DASHJS_LOW_LATENCY_CATCH_UP_PLAYBACK_RATE_MIN,
  DASHJS_CMCD_ENABLED,
  DASHJS_CMSD_ENABLED,
  DASHJS_AV_RESYNC_INTERVAL_SEC,
  LIVESTREAM_AVAILABILITY_CHECK_TIMEOUT_MSEC,
  LIVESTREAM_PLAYER_WAITING_THRESHOLD_MSEC,
  LIVESTREAM_FLV_MIN_BUFFER,
  LIVESTREAM_FLV_MAX_BUFFER,
  LIVESTREAM_PAY_DURATION_SECONDS,
  BYTEPLUS_RTC_ROOM_PROFILE_TYPE,
  BYTEPLUS_RTC_REMOTE_VIDEO_CONFIG_WIDTH,
  BYTEPLUS_RTC_REMOTE_VIDEO_CONFIG_HEIGHT,
  FLIX_PRICE_VALUE,
  AUTO_MESSAGE_PRICE_VALUE,
  LIVESTREAM_PRIVATE_PRICE_VALUE,
  LIVESTREAM_SHOW_TICKET_PRICE_VALUE,
  LIVESTREAM_SHOW_EARLY_BIRD_TICKET_PRICE_VALUE,
  SHORTS_CTA_DELAY_SECONDS,
  LIVESTREAM_SHORTS_CTA_DELAY_SECONDS,
  REFERRAL_REFERRER_REWARD_VALUE,
  REFERRAL_REFERRAL_REWARD_VALUE,
  FEATURE_USERNAME_SIGN_UP_EMAIL_INPUT,
  SHORTS_NEED_AUTH_THRESHOLD,
  FEATURE_STORY_VOLUME_BUTTON,
  POST_VIDEO_LENGTH_MIN,
  POST_VIDEO_LENGTH_MAX,
  GAME_NEXT_SESSION_THRESHOLD_SECONDS,
  INSERT_CLIP_AFTER_X_SHORTS,
  INSERT_CLIP_EVERY_X_SHORTS,
  INSERT_AD_AFTER_X_SHORTS,
  INSERT_AD_EVERY_X_SHORTS,
  CREATOR_OUTBOX_FEED_STORY_PIN_MAX,
  CREATOR_OUTBOX_FEED_POST_VIDEO_PIN_MAX,
  CREATOR_OUTBOX_FEED_POST_IMAGE_PIN_MAX,
  CREATOR_OUTBOX_FEED_SHORT_PIN_MAX,
  SHORT_VIDEO_LENGTH_MIN,
  FEATURE_CLIP_POST,
  LIVESTREAM_OPERATION_HINT,
  LIVESTREAM_EXIT_ALERT,
  FOLLOWING_PREFETCHED_MESSAGE_AMOUNT,
  SHORTS_PREFETCHED_MESSAGE_AMOUNT,
  TRAILER_VIDEO_UPLOAD_ENABLED,
  TRAILER_POST_VIDEO_LENGTH_SEC_MIN,
  PUSHER_STALLED_CHANNEL_CHECK_INTERVAL,
  PUSHER_STALLED_CHANNEL_TRACK_LIMIT,
  FEATURE_HIDE_POST,
  MAX_VALID_AGE_VERIFIED_MSEC,
  SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE,
  LIVESTREAM_AVATAR_QUEUE_DELAY_MSEC,
  LIVESTREAM_CHAT_LIST_LIMITATION,
  WEB_AUTHN_DESKTOP_SUPPORT_ENABLED,
  WEB_AUTHN_REGISTRATION_TIMEOUT_MSEC,
  WEB_AUTHN_AUTHENTICATION_TIMEOUT_MSEC,
  SCHEDULE_POST_DEFAULT_DURATION_SEC,
  OUTBOX_FEED_DELAY_TIME_IN_SECONDS,
  GAME_AQUISITION_AUTH_TOKEN_THRESHOLD_SECONDS,
  FEATURE_SECURIONPAY_SHOP_AGREEMENT,
  FEATURE_TAPPAY_SHOP_AGREEMENT,
  FEATURE_ECPAY_SHOP_AGREEMENT,
  FEATURE_SIGNUP_BIRTHDATE_INPUT,
  FEATURE_EDIT_POST,
  PUSHER_CHANNEL_DATA_POLLING_MSEC,
  FEATURE_MESSAGE_CATEGORY,
  TRENDING_CATEGORY_FETCH_LIMIT,
  MESSAGE_CATEGORY_SELECTION_COUNT_MAX,
  MESSAGE_CATEGORY_SELECTION_COUNT_MIN,
  FEATURE_EXPIRE_POST,
  FEATURE_EXPIRE_EDIT_POST,
  EARLYBIRD_DISPLAY_TICKET_DIAMOND,
  SHOW_DISPLAY_TICKET_DIAMOND,
  FEATURE_MINI_PROFILE,
  FEATURE_SETTINGS_REDEEM,
  FEATURE_SETTINGS_ABOUT_SWAG,
  FEATURE_EMAIL_BINDING_TOASTR,
  IMPRESSION_TRACKING_VISIBILITY_RATIO,
  IMPRESSION_TRACKING_DURATION_SECONDS,
  RESEND_PHONE_VERIFIED_CODE_SEC,
  RESEND_EMAIL_VERIFIED_CODE_SEC,
  VIP_LEVEL_LIVESTREAM_NOTIFY_THRESHOLD,
  VIP_LEVEL_INFORM_EXPIRE_SOON_DAYS,
  LIVESTREAM_VIEWERS_MAX,
  LIVESTREAM_VIEWERS_MIN,
  FEATURE_LIVESTREAM_OVERLAY_CTA,
  INTERCOM_UPDATE_DEBOUNCE_MSEC,
  MIXPANEL_RECORD_SESSIONS_PERCENT,
  LIVESTREAM_CHAT_SCROLL_DETECT_ITEM_COUNT,
  POST_IMAGE_DIAMOND_MIN,
  POST_IMAGE_DIAMOND_MAX,
  POST_IMAGE_DIAMOND_INTERVAL,
  POST_IMAGE_DIAMOND_DEFAULT,
];

/**
 * Select remote config data by data key
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} dataKey - select key.
 * @param {string} configId - configuration id.
 * @param {string} dataObject - data object.
 * @return {*} The selected remote config data.
 */
const getRemoteConfigData = createCachedSelector(
  state => state.get('remoteConfig'),
  (state, dataKey) => dataKey,
  (state, dataKey, configId) =>
    state.getIn(['operations', 'config', 'configurationData', configId]),
  (state, dataKey, configId, dataObject) =>
    dataObject != null ? JSON.stringify(dataObject) : undefined,
  (
    baseRemoteConfig = defaultMap,
    dataKey,
    configIdRemoteConfig = defaultMap,
    dataJson
  ) => {
    const remoteConfig = baseRemoteConfig.mergeDeep(configIdRemoteConfig);
    const templateDataObject = dataJson ? JSON.parse(dataJson) : undefined;
    let result = replaceTemplate({
      string: remoteConfig?.get(dataKey),
      data: templateDataObject,
    });
    if (
      needSplitKeys.includes(dataKey) ||
      needSplitKeysWithPrefix.some(el => dataKey?.startsWith(el))
    ) {
      result =
        result?.split(' ').filter(a => !(a == null || a === '')) || defaultList;
    } else if (needNumberKeys.includes(dataKey)) {
      const value = remoteConfig?.get(dataKey);
      result = value == null ? value : Number(remoteConfig?.get(dataKey));
    } else if (dataKey === 'splashVideo') {
      const hls = remoteConfig?.get(FLIX_SPLASH_HLS);
      const dash = remoteConfig?.get(FLIX_SPLASH_DASH);
      const publicLogoUrlPath = remoteConfig?.get(PUBLIC_LOGO_URL_PATH);
      const hlsFallbackUrl = getResourceUrl({
        path: publicLogoUrlPath,
        endpoint: '/splash.mp4',
        resourceType: PUBLIC,
      });
      const dashFallbackUrl = getResourceUrl({
        path: publicLogoUrlPath,
        endpoint: '/splash.mp4',
        resourceType: PUBLIC,
      });
      result = {
        hls: hls || hlsFallbackUrl.href,
        dash: dash || dashFallbackUrl.href,
      };
    } else if (dataKey === 'liveStreamDisplayUsernames') {
      result =
        Number.parseInt(
          remoteConfig?.get(FEATURE_LIVESTREAM_DISPLAY_USERNAMES)
        ) || 0;
    } else if (dataKey === 'allowedLoginMethods') {
      result = LOGIN_METHODS.reduce((acc, key) => {
        const loginMethodKeys = remoteConfig
          ?.get(key)
          ?.split(' ')
          .filter(item => item);
        loginMethodKeys && acc.push(...loginMethodKeys);
        return acc;
      }, []);
      result = [...new Set(result)];
    } else if (dataKey === 'paymentSourceStyle') {
      if (remoteConfig) {
        result = [...remoteConfig.keys()]
          .filter(key => key.startsWith('PAYMENT_COLORS_'))
          .reduce((acc, curr) => {
            acc[curr] = remoteConfig.get(curr);
            return acc;
          }, {});
      }
    } else if (dataKey === 'shopColorBadges') {
      if (remoteConfig) {
        result = [...remoteConfig.keys()]
          .filter(key => key.startsWith('SHOP_COLOR_BADGES_'))
          .reduce(
            (acc, curr) => {
              const keys = remoteConfig.get(curr);
              keys.split(' ').forEach(key => {
                acc[key] = curr.replace('SHOP_COLOR_BADGES_', '');
              });
              return acc;
            },
            {
              day_limited: 'cf0404',
              week_limited: 'cf0404',
              month_limited: 'cf0404',
              discount: 'ff7878',
              default: 'E3E3E3',
            }
          );
      }
    } else if (dataKey === SUPPORTED_LANGUAGES) {
      const idsString = remoteConfig?.get(dataKey);
      result = idsString
        ? idsString.split(' ').map(lang => {
            if (lang.toLowerCase() === 'zh-hant') return 'zh-TW';
            else if (lang.toLowerCase() === 'zh') return 'zh-CN';
            else return lang;
          })
        : undefined;
    } else if (
      [KYC_SUPPORTED_COUNTRIES, HASHTAG_CATEGORIES].includes(dataKey)
    ) {
      result = remoteConfig?.get(dataKey)?.split(' ');
    } else if (
      dataKey === CREDIT_CARD_TYPES_TAPPAY ||
      dataKey === CREDIT_CARD_TYPES_SECURIONPAY
    ) {
      result = remoteConfig?.get(dataKey)?.split(' ');
    } else if (
      [FLIX_DISPLAY_UNLOCK_COUNT, HOME_HEADER_DISPLAY_FREE_BADGE].includes(
        dataKey
      )
    ) {
      result = remoteConfig?.get(dataKey) === 'true';
    } else if (
      [
        'creatorReplyPriceMin',
        'creatorReplyPriceMax',
        'creatorReplyPriceInterval',
      ].includes(dataKey)
    ) {
      const [min, max, interval] =
        remoteConfig
          ?.get(CP_REPLY_DIAMOND_MIN_MAX_INTERVAL)
          ?.split(' ')
          ?.map(item => +item) || [];
      result =
        dataKey === 'creatorReplyPriceMin'
          ? min
          : dataKey === 'creatorReplyPriceMax'
            ? max
            : interval;
    } else if (
      [
        POST_DIAMOND_MIN,
        POST_DIAMOND_MAX,
        POST_DIAMOND_INTERVAL,
        POST_DIAMOND_DEFAULT,
        'postVideoDurationCheckpoints',
      ].includes(dataKey)
    ) {
      const isDurationCheckpoints = 'postVideoDurationCheckpoints' === dataKey;
      const string =
        remoteConfig.get(isDurationCheckpoints ? POST_DIAMOND_MIN : dataKey) ||
        '';
      if (string.includes(':')) {
        result = string
          .split(' ')
          .filter(item => !!item)
          .map(item => {
            const execResult = /(?<duration>[\d]+)s:(?<price>[\d]+)/.exec(item);
            if (execResult) {
              return {
                duration: Number(execResult.groups?.duration),
                price: Number(execResult.groups?.price),
              };
            }
            return {
              duration: 0,
              price: Number(item),
            };
          })
          .sort((a, b) => {
            const { duration: aDuration } = a;
            const { duration: bDuration } = b;
            return bDuration - aDuration;
          })
          .map(item => (isDurationCheckpoints ? item.duration : item.price));
      } else {
        result = [Number(isDurationCheckpoints ? 0 : string)];
      }
    } else if (
      [
        SWAG_SOCIAL_MEDIA_LINKS,
        SWAG_ABOUT_LINKS,
        SWAG_SUPPORT_LINKS,
        SWAG_WORK_LINKS,
        SWAG_DISCOVER_LINKS,
      ].includes(dataKey)
    ) {
      const remoteConfigKeys = [...remoteConfig.keys()];
      result = (
        result?.split(' ').filter(a => !(a == null || a === '')) || defaultList
      ).map(item => {
        const relatedKeyPrefix = `${dataKey}_${item.toUpperCase()}_`;
        const relatedEntries = remoteConfigKeys
          .filter(key => key.startsWith(relatedKeyPrefix))
          .map(remoteConfigKey => {
            const key = remoteConfigKey
              .replace(relatedKeyPrefix, '')
              .toLowerCase();
            return [key, remoteConfig.get(remoteConfigKey)];
          });
        const object = {
          title: item,
          mixpanel: {},
        };
        relatedEntries.forEach(entry => {
          const [key, value] = entry;
          const mixpanelPrefix = 'mixpanel_';
          if (key.startsWith(mixpanelPrefix)) {
            const mixpanelProperty = key
              .replace(mixpanelPrefix, '')
              .replace('_', '.');
            object.mixpanel = {
              ...object.mixpanel,
              [mixpanelProperty]: value,
            };
          } else {
            object[key] = value;
          }
        });
        return object;
      });
    }

    return result instanceof Collection ? result.toJS() : result;
  }
)(
  (state, dataKey, configId, dataObject) =>
    `${dataKey}:${configId}:${
      dataObject != null ? JSON.stringify(dataObject) : dataObject
    }`
);

export default getRemoteConfigData;
